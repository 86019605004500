import './App.css';
import React, {lazy, Suspense} from 'react';
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SearchBar from "../Components/SearchBar/SearchBar";
import AccountBar from "../Components/AccountBar/AccountBar";
import Menu from "../Components/Menu/Menu";
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {LinearProgress} from "@mui/material";
import Announcement from "../Components/Announcement/Announcement";
const Upload = lazy(() => import("../Pages/Upload/Upload"));
const Home = lazy(() => import("../Pages/Home/Home"));
const Donation = lazy(() => import("../Pages/Donation/Donation"));
const ContactUs = lazy(() => import("../Pages/ContactUs/ContactUs"));
const BooksBrowseAll = lazy(() => import("../Pages/Books/Browse/BooksBrowseAll"));
const Login = lazy(() => import("../Pages/Login/Login"));
const Register = lazy(() => import("../Pages/Register/Register"));
const BooksBrowseRecent = lazy(() => import("../Pages/Books/Browse/BooksBrowseRecent"));
const BooksBrowsePopular = lazy(() => import("../Pages/Books/Browse/BooksBrowsePopular"));
const BooksBrowseFavourite = lazy(() => import("../Pages/Books/Browse/BooksBrowseFavourite"));
const ActivateAccount = lazy(() => import("../Pages/ActivateAccount/ActivateAccount"));
const ForgotPassword = lazy(() => import("../Pages/ForgotPassword/ForgotPassword"));
const Logout = lazy(() => import("../Pages/Logout/Logout"));
const BooksBrowseTrending = lazy(() => import("../Pages/Books/Browse/BooksBrowseTrending"));
const BookDownload = lazy(() => import("../Pages/Books/Download/BookDownload"));
const DonationSuccessful = lazy(() => import("../Pages/Donation/DonationSuccessful"));
const DonationError = lazy(() => import("../Pages/Donation/DonationError"));
const MyAccount = lazy(() => import("../Pages/MyAccount/MyAccount"));
const SetPassword = lazy(() => import("../Pages/SetPassword/SetPassword"));
const SetPasswordSuccessful = lazy(() => import("../Pages/SetPassword/SetPasswordSuccessful"));
const RecoveryEmailSent = lazy(() => import("../Pages/ForgotPassword/RecoveryEmailSent"));
const FAQ = lazy(() => import("../Pages/FAQ/FAQ"));
const PageNotFound = lazy(() => import("../Pages/PageNotFound/PageNotFound"));
const ActivateSuccessful = lazy(() => import("../Pages/ActivateAccount/ActivateSuccessful"));
const ActivateRequest = lazy(() => import("../Pages/ActivateAccount/ActivateRequest"));
const ActivateEmailSent = lazy(() => import("../Pages/ActivateAccount/ActivateEmailSent"));
const ManageAudio = lazy(() => import("../Pages/MyAccount/Admin/ManageAudio"));
const ManageVideos = lazy(() => import("../Pages/MyAccount/Admin/ManageVideos"));
const ManageBooks = lazy(() => import("../Pages/MyAccount/Admin/ManageBooks"));
const Statistics = lazy(() => import("../Pages/MyAccount/Admin/Statistics"));
const ManageUsers = lazy(() => import("../Pages/MyAccount/Admin/ManageUsers"));
const SearchIndexes = lazy(() => import("../Pages/MyAccount/Admin/SearchIndexes"));
const VideoBrowseAll = lazy(() => import("../Pages/Video/Browse/VideoBrowseAll"));
const VideoBrowseTrending = lazy(() => import("../Pages/Video/Browse/VideoBrowseTending"));
const VideoBrowseFavourite = lazy(() => import("../Pages/Video/Browse/VideoBrowseFavourite"));
const VideoBrowsePopular = lazy(() => import("../Pages/Video/Browse/VideoBrowsePopular"));
const VideoBrowseRecent = lazy(() => import("../Pages/Video/Browse/VideoBrowseRecent"));
const VideoDownload = lazy(() => import("../Pages/Video/Download/VideoDownload"));
const AudioBrowseAll = lazy(() => import("../Pages/Audio/Browse/AudioBrowseAll"));
const AudioBrowseTrending = lazy(() => import("../Pages/Audio/Browse/AudioBrowseTending"));
const AudioBrowseFavourite = lazy(() => import("../Pages/Audio/Browse/AudioBrowseFavourite"));
const AudioBrowsePopular = lazy(() => import("../Pages/Audio/Browse/AudioBrowsePopular"));
const AudioBrowseRecent = lazy(() => import("../Pages/Audio/Browse/AudioBrowseRecent"));
const AudioDownload = lazy(() => import("../Pages/Audio/Download/AudioDownload"));

class App extends React.Component {

  state = {
    showMenu: false,
    breadcrumbs: []
  }

  toggleMenu() {
    this.setState(prev => ({
      showMenu: !prev.showMenu
    }));
  }

  closeMenu() {
    this.setState(() => ({
      showMenu: false
    }));
  }

  setBreadcrumbs(breadcrumbs) {
    this.setState(() => ({
      breadcrumbs: breadcrumbs
    }));
  }

  render() {
    return (
      <div className="app">
        {this.props.children}
        <Router>
          <AccountBar/>
          <Header/>
          <SearchBar menuOpen={this.state.showMenu} onMenuToggle={this.toggleMenu.bind(this)}/>
          <Announcement/>
          <div className="container app-body">
            <Menu showMenu={this.state.showMenu} onMenuClose={this.closeMenu.bind(this)}/>
            <div className="page">
              <div className="breadcrumbs">
                <div className="breadcrumbs-overflow"/>
                <Link to="/">Home</Link>
                {
                  this.state.breadcrumbs.map(function (breadcrumb) {
                    return <React.Fragment key={breadcrumb.label}><NavigateNextIcon/> <Link
                      to={breadcrumb.link}>{breadcrumb.label}</Link></React.Fragment>;
                  })
                }
              </div>
              <div className="page-content">
                <Switch>
                  <Route path="/books/browse/recent">
                    <Suspense fallback={<LinearProgress/>}>
                      <BooksBrowseRecent setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/books/browse/most-popular">
                    <Suspense fallback={<LinearProgress/>}>
                      <BooksBrowsePopular setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/books/browse/trending">
                    <Suspense fallback={<LinearProgress/>}>
                      <BooksBrowseTrending setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/books/browse/favourites">
                    <Suspense fallback={<LinearProgress/>}>
                      <BooksBrowseFavourite setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/books/browse">
                    <Suspense fallback={<LinearProgress/>}>
                      <BooksBrowseAll setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/books/:id">
                    <Suspense fallback={<LinearProgress/>}>
                      <BookDownload setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/audio/browse/recent">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioBrowseRecent setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/audio/browse/most-popular">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioBrowsePopular setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/audio/browse/favourites">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioBrowseFavourite setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/audio/browse/trending">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioBrowseTrending setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/audio/browse">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioBrowseAll setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/audio/:id">
                    <Suspense fallback={<LinearProgress/>}>
                      <AudioDownload setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/video/browse/recent">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoBrowseRecent setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/video/browse/most-popular">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoBrowsePopular setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/video/browse/favourites">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoBrowseFavourite setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/video/browse/trending">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoBrowseTrending setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/video/browse">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoBrowseAll setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/video/:id">
                    <Suspense fallback={<LinearProgress/>}>
                      <VideoDownload setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/donation/success">
                    <Suspense fallback={<LinearProgress/>}>
                      <DonationSuccessful setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/donation/error">
                    <Suspense fallback={<LinearProgress/>}>
                      <DonationError setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/donation">
                    <Suspense fallback={<LinearProgress/>}>
                      <Donation setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/upload">
                    <Suspense fallback={<LinearProgress/>}>
                      <Upload setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/contact-us">
                    <Suspense fallback={<LinearProgress/>}>
                      <ContactUs setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/login">
                    <Suspense fallback={<LinearProgress/>}>
                      <Login setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/logout">
                    <Suspense fallback={<LinearProgress/>}>
                      <Logout setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/forgot-password/email-sent">
                    <Suspense fallback={<LinearProgress/>}>
                      <RecoveryEmailSent setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/forgot-password">
                    <Suspense fallback={<LinearProgress/>}>
                      <ForgotPassword setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/set-password/success">
                    <Suspense fallback={<LinearProgress/>}>
                      <SetPasswordSuccessful setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/set-password/:userId/:token">
                    <Suspense fallback={<LinearProgress/>}>
                      <SetPassword setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/register">
                    <Suspense fallback={<LinearProgress/>}>
                      <Register setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/activate/successful">
                    <Suspense fallback={<LinearProgress/>}>
                      <ActivateSuccessful setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/activate/failed">
                    <Suspense fallback={<LinearProgress/>}>
                      <ActivateRequest setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/activate/request">
                    <Suspense fallback={<LinearProgress/>}>
                      <ActivateRequest setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/activate/email-sent">
                    <Suspense fallback={<LinearProgress/>}>
                      <ActivateEmailSent setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/activate/:userId/:token">
                    <Suspense fallback={<LinearProgress/>}>
                      <ActivateAccount setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/my-account/admin/manage/audio">
                    <Suspense fallback={<LinearProgress/>}>
                      <ManageAudio setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account/admin/manage/books">
                    <Suspense fallback={<LinearProgress/>}>
                      <ManageBooks setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account/admin/manage/video">
                    <Suspense fallback={<LinearProgress/>}>
                      <ManageVideos setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account/admin/search-indexes">
                    <Suspense fallback={<LinearProgress/>}>
                      <SearchIndexes setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account/admin/stats">
                    <Suspense fallback={<LinearProgress/>}>
                      <Statistics setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account/admin/users">
                    <Suspense fallback={<LinearProgress/>}>
                      <ManageUsers setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                  <Route path="/my-account">
                    <Suspense fallback={<LinearProgress/>}>
                      <MyAccount setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/faq">
                    <Suspense fallback={<LinearProgress/>}>
                      <FAQ setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route path="/" exact={true}>
                    <Suspense fallback={<LinearProgress/>}>
                      <Home setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>

                  <Route>
                    <Suspense fallback={<LinearProgress/>}>
                      <PageNotFound setBreadcrumbs={this.setBreadcrumbs.bind(this)}/>
                    </Suspense>
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
        <Footer/>
      </div>
    );
  }
}

export default App;
