import './SearchBar.css';
import * as React from 'react';
import {withRouter} from "react-router-dom";
import {Tooltip} from "@mui/material";

class SearchBar extends React.Component {
  searchField = React.createRef();
  selectBox = React.createRef();
  clearButton = React.createRef();

  onMenuToggle() {
    if (this.props.onMenuToggle) {
      this.props.onMenuToggle();
    }
  }

  onKeyPressed(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const type = this.selectBox.current.value;
    const text = this.searchField.current.value;
    const searchText = encodeURIComponent(text);

    if (type === "forum") {
      window.location.href = "/forum/search?Search=" + searchText;
    } else if (type === "books") {
      this.props.history.push("/books/browse/all" + this.createParams("books", searchText));
    } else if (type === "audio") {
      this.props.history.push("/audio/browse/all" + this.createParams("audio", searchText));
    } else if (type === "video") {
      this.props.history.push("/video/browse/all" + this.createParams("video", searchText));
    }

    this.searchField.current.blur();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.location.search.includes("searchText")) {
      this.searchField.current.value = "";
    } else if (this.getSearchText(this.props) !== this.getSearchText(prevProps)) {
      this.searchField.current.value = this.getSearchText(this.props);
    }

    this.resetSelectBox();
    this.updateClearButton();
  }

  resetSelectBox() {
    const pathname = this.props.location.pathname;

    if (pathname.startsWith("/audio")) {
      this.selectBox.current.value = "audio";
    } else if (pathname.startsWith("/books")) {
      this.selectBox.current.value = "books";
    } else if (pathname.startsWith("/video")) {
      this.selectBox.current.value = "video";
    }
  }

  createParams(type, searchText) {
    if (this.props.location.pathname.startsWith("/" + type)) {
      const params = new URLSearchParams(this.props.location.search);

      if (searchText) {
        params.set("searchText", searchText);
      } else {
        params.delete("searchText");
      }

      params.delete("page");
      return "?" + params.toString();
    } else {
      return searchText ? "?searchText=" + searchText : "";
    }
  }

  getSearchText(props) {
    const queryParams = new URLSearchParams(props.location.search);
    const searchText = queryParams.get("searchText");

    if (searchText) {
      return decodeURIComponent(searchText);
    } else {
      return "";
    }
  }

  clearSearch() {
    this.resetSelectBox();
    this.searchField.current.value = "";

    const params = new URLSearchParams(this.props.location.search);

    if(params.has("searchText")) {
      params.delete("searchText");
      this.props.history.push(this.props.location.pathname + (params.toString() ? "?" + params.toString() : ""));
    }

    this.updateClearButton();
  }

  updateClearButton() {
    if (this.searchField.current.value) {
      this.clearButton.current.classList.remove("clear-button-hidden");
    } else {
      this.clearButton.current.classList.add("clear-button-hidden");
    }
  }

  render() {
    return (
        <div className="search-bar">
          <div className="container">
            <div className="search-bar-container">
              <div className={"search-bar-menu " + (this.props.menuOpen ? "search-bar-menu-open" : "")}>
                <button className="search-bar-menu-button" type="button" onClick={this.onMenuToggle.bind(this)} aria-label={"Menu"}>
                  <Tooltip title="Menu" arrow open={!this.props.menuOpen} placement={"top"} classes={{ popper: "search-bar-menu-tooltip" }}>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                    </svg>
                  </Tooltip>
                </button>
              </div>
              <div className="search-bar-search">
                <div className="search-bar-search-wrapper">
                  <select ref={this.selectBox} className="search-select" name="search-type" defaultValue="books">
                    <option value="books">Books</option>
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                    <option value="forum">Forum</option>
                  </select>
                  <div className="v-divider"/>
                  <input ref={this.searchField} className="search-input" type="text" name="search-text" placeholder="Enter search text" defaultValue={this.getSearchText(this.props)} onKeyPress={this.onKeyPressed.bind(this)} onChange={this.updateClearButton.bind(this)}/>
                  <button ref={this.clearButton} className="clear-button" onClick={this.clearSearch.bind(this)}>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                    </svg>
                  </button>
                  <div className="v-divider" style={{marginRight: 0}}/>
                  <button className="search-button" onClick={this.handleSubmit.bind(this)} aria-label={"Search"}>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(SearchBar);
