import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import {HelmetProvider} from 'react-helmet-async';
import ReactGA from 'react-ga4';
import './index.css';

ReactGA.initialize('UA-12341843-3');

ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
