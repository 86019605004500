import './Announcement.css';

function Announcement() {
  return (
      <div className="announcement title-bar-text">
          <div className={"announcement-text"}>
          Please recite a Surah e Fatiha for my parents: Syed Zaki Hasan Jafri & Malika Begum
          </div>
      </div>
  );
}

export default Announcement;
