import {getCookie} from "./GetCookie";
import jwt_decode from "jwt-decode";

export function getUserDetails() {
  const token = getCookie("AuthToken");

  if (token) {
    try {
      return jwt_decode(token);
    } catch (e) {
      // ignore error - return null
    }

    return null;
  }
}

export function isLoggedIn() {
  return getUserDetails() != null;
}

export function isAdmin() {
  let privileges = getUserDetails()?.privileges || [];
  return privileges.includes("ADMIN");
}

export function isDonator() {
  let privileges = getUserDetails()?.privileges || [];
  return privileges.includes("DONATOR");
}
