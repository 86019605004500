import './Footer.css';
import * as React from "react";

function Footer() {
  return (
      <div className="footer">
        Copyright &copy; {new Date().getFullYear()} Jafri Library. All Rights Reserved.
         <div className={"footer-recaptha"}>
           This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
         </div>
      </div>
  );
}

export default Footer;
