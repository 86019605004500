import * as React from "react";
import {Helmet} from "react-helmet-async";
import {isAdmin} from "./Authentication";

export function isAdsEnabled() {
  return !isAdmin() && (window.location.host === "jafrilibrary.com" || window.location.host.startsWith("localhost"));
}

export function showAds(slot) {
  return (
    <React.Fragment>
      <ins className="adsbygoogle"
           style={{display: "block"}}
           data-ad-client="ca-pub-9383473173054201"
           data-ad-slot={slot}
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <Helmet>
        <script>
          {`
            // Loading Advertisement: ${slot}
            setTimeout(() => (window.adsbygoogle = window.adsbygoogle || []).push({}), 500);
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
}

