import './AccountBar.css';
import {Link, withRouter} from "react-router-dom";
import * as React from "react";
import {getUserDetails} from "../../Functions/Authentication";

class AccountBar extends React.Component {
  state = {
    userId: null
  };

  componentDidMount() {
    this.renewToken();
    this.interval = setInterval(this.renewToken.bind(this), 60000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const userId = this.getUserId();

    if (userId !== this.state.userId) {
      this.setState({
        userId: userId
      });
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  getUserId() {
    return getUserDetails()?.sub;
  }

  renewToken() {
    if (this.getUserId()) {
      fetch('/api/users/renew-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.ok) {
          return response;
        } else {
          throw new Error(response.status.toString())
        }
      })
      .catch(reason => {
        if (this.state.userId) {
          this.setState({
            userId: null
          });
        }
      });
    } else {
      if (this.state.userId) {
        this.setState({
          userId: null
        });
      }
    }
  }

  render() {
    let redirectPath = this.props.location.pathname + this.props.location.search || "/";

    if (redirectPath.match("^/(login|logout|register|activate|reset-password|forgot-password).*")) {
      redirectPath = "/";
    }

    return (
        <div className="account-bar">
          <div className="container">
            <div className="account-bar-buttons" style={this.state.userId == null ? {} : {display: "none"}}>
              <Link to={{pathname: '/login', state: {redirectTo: redirectPath}}}>
                <svg className="account-bar-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"/>
                </svg>
                Login
              </Link>
              <div className="account-bar-divider"/>
              <Link to="/register">
                <svg className="account-bar-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M10 4h4v4h-4zM4 16h4v4H4zm0-6h4v4H4zm0-6h4v4H4zm10 8.42V10h-4v4h2.42zm6.88-1.13-1.17-1.17c-.16-.16-.42-.16-.58 0l-.88.88L20 12.75l.88-.88c.16-.16.16-.42 0-.58zM11 18.25V20h1.75l6.67-6.67-1.75-1.75zM16 4h4v4h-4z"/>
                </svg>
                Register
              </Link>
            </div>
            <div className="account-bar-buttons" style={this.state.userId == null ? {display: "none"} : {}}>
              <Link to="/my-account">
                <svg className="account-bar-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <circle cx="10" cy="8" r="4"/>
                  <path d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26c-.79-1.13-1.26-2.51-1.26-4 0-1.07.25-2.07.67-2.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                </svg>
                My Account
              </Link>
              <div className="account-bar-divider"/>
              <Link to={{pathname: '/logout', state: {redirectTo: redirectPath}}}>
                <svg className="account-bar-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/>
                </svg>
                Logout
              </Link>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(AccountBar);
