import './Menu.css';
import * as React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {isLoggedIn} from "../../Functions/Authentication";
import {refreshMetaTags} from "../../Functions/Header";
import ReactGA from 'react-ga4';
import {isAdsEnabled, showAds} from "../../Functions/Ads";

class Menu extends React.Component {

  state = {
    booksExpanded: false,
    audioExpanded: false,
    videoExpanded: false
  };

  componentDidMount() {
    let path = this.props.location.pathname;

    if (path.startsWith("/books")) {
      this.toggleBooks();
    } else if (path.startsWith("/audio")) {
      this.toggleAudio();
    } else if (path.startsWith("/video")) {
      this.toggleVideo();
    }

    refreshMetaTags(path);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      refreshMetaTags(this.props.location.pathname);
      ReactGA.send({ hitType: "pageview", page: this.props.location.pathname + this.props.location.search });
      this.props.onMenuClose();
    }
  }

  toggleBooks() {
    this.setState(prev => ({
      booksExpanded: !prev.booksExpanded,
      audioExpanded: false,
      videoExpanded: false
    }));
  }

  toggleAudio() {
    this.setState(prev => ({
      booksExpanded: false,
      audioExpanded: !prev.audioExpanded,
      videoExpanded: false
    }));
  }

  toggleVideo() {
    this.setState(prev => ({
      booksExpanded: false,
      audioExpanded: false,
      videoExpanded: !prev.videoExpanded
    }));
  }

  render() {
    return (
        <div className="menu">
          <div className={"menu-list-container " + (this.props.showMenu ? "menu-list-container-show" : "menu-list-container-hide")}>
            <div className="menu-list">
              <div className="menu-item">
                <NavLink to="/" exact={true}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
                  </svg>
                  <div className="menu-text">Home</div>
                </NavLink>
              </div>
              <div className="menu-item">
                <NavLink to="/donation" exact={true}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z"/>
                  </svg>
                  <div className="menu-text">Donation</div>
                </NavLink>
              </div>
              <div className={"menu-item " + (this.state.booksExpanded ? "expanded" : "")}>
                <div className="menu-item-group" onClick={this.toggleBooks.bind(this)}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z"/>
                    <path d="M17.5 10.5c.88 0 1.73.09 2.5.26V9.24c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99zM13 12.49v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26V11.9c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.3-4.5.83zm4.5 1.84c-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26v-1.52c-.79-.16-1.64-.24-2.5-.24z"/>
                  </svg>
                  <div className="menu-text">Books</div>
                  <svg className="svg-icon menu-arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
                  </svg>
                </div>
                <div className="menu-item-children">
                  <NavLink to="/books/browse/all" isActive={() => this.props.location.pathname === "/books/browse/all" || this.props.location.pathname.match("\\/books\\/[0-9]*$")}>
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M14.94 4.66h-4.72l2.36-2.36zm-4.69 14.71h4.66l-2.33 2.33zM6.1 6.27 1.6 17.73h1.84l.92-2.45h5.11l.92 2.45h1.84L7.74 6.27H6.1zm-1.13 7.37 1.94-5.18 1.94 5.18H4.97zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z"/>
                    </svg>
                    <div className="menu-text">Browse All</div>
                  </NavLink>
                  <NavLink to="/books/browse/recent">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 15H7.3l-2.55-3.5V15H3.5V9h1.25l2.5 3.5V9H8.5v6zm5-4.74H11v1.12h2.5v1.26H11v1.11h2.5V15h-4V9h4v1.26zm7 3.74c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9h1.25v4.51h1.13V9.99h1.25v3.51h1.12V9h1.25v5z"/>
                    </svg>
                    <div className="menu-text">Recently Added</div>
                  </NavLink>
                  <NavLink to="/books/browse/most-popular">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
                    </svg>
                    <div className="menu-text">Most Popular</div>
                  </NavLink>
                  <NavLink to="/books/browse/trending">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/>
                    </svg>
                    <div className="menu-text">Trending</div>
                  </NavLink>
                  <NavLink to="/books/browse/favourites">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
                    </svg>
                    <div className="menu-text">Favourites</div>
                  </NavLink>
                </div>
              </div>
              <div className={"menu-item " + (this.state.audioExpanded ? "expanded" : "")}>
                <div className="menu-item-group" onClick={this.toggleAudio.bind(this)}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M17 2H7c-1.1 0-2 .9-2 2v16c0 1.1.9 1.99 2 1.99L17 22c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5 2c1.1 0 2 .9 2 2s-.9 2-2 2c-1.11 0-2-.9-2-2s.89-2 2-2zm0 16c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                  </svg>
                  <div className="menu-text">Audio</div>
                  <svg className="svg-icon menu-arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
                  </svg>
                </div>
                <div className="menu-item-children">
                  <NavLink to="/audio/browse/all" isActive={() => this.props.location.pathname === "/audio/browse/all" || this.props.location.pathname.match("\\/audio\\/[0-9]*$")}>
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M14.94 4.66h-4.72l2.36-2.36zm-4.69 14.71h4.66l-2.33 2.33zM6.1 6.27 1.6 17.73h1.84l.92-2.45h5.11l.92 2.45h1.84L7.74 6.27H6.1zm-1.13 7.37 1.94-5.18 1.94 5.18H4.97zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z"/>
                    </svg>
                    <div className="menu-text">Browse All</div>
                  </NavLink>
                  <NavLink to="/audio/browse/recent">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 15H7.3l-2.55-3.5V15H3.5V9h1.25l2.5 3.5V9H8.5v6zm5-4.74H11v1.12h2.5v1.26H11v1.11h2.5V15h-4V9h4v1.26zm7 3.74c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9h1.25v4.51h1.13V9.99h1.25v3.51h1.12V9h1.25v5z"/>
                    </svg>
                    <div className="menu-text">Recently Added</div>
                  </NavLink>
                  <NavLink to="/audio/browse/most-popular">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
                    </svg>
                    <div className="menu-text">Most Popular</div>
                  </NavLink>
                  <NavLink to="/audio/browse/trending">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/>
                    </svg>
                    <div className="menu-text">Trending</div>
                  </NavLink>
                  <NavLink to="/audio/browse/favourites">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
                    </svg>
                    <div className="menu-text">Favourites</div>
                  </NavLink>
                </div>
              </div>
              <div className={"menu-item " + (this.state.videoExpanded ? "expanded" : "")}>
                <div className="menu-item-group" onClick={this.toggleVideo.bind(this)}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12zm-5-6-7 4V7z"/>
                  </svg>
                  <div className="menu-text">Video</div>
                  <svg className="svg-icon menu-arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
                  </svg>
                </div>
                <div className="menu-item-children">
                  <NavLink to="/video/browse/all" isActive={() => this.props.location.pathname === "/video/browse/all" || this.props.location.pathname.match("\\/video\\/[0-9]*$")}>
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M14.94 4.66h-4.72l2.36-2.36zm-4.69 14.71h4.66l-2.33 2.33zM6.1 6.27 1.6 17.73h1.84l.92-2.45h5.11l.92 2.45h1.84L7.74 6.27H6.1zm-1.13 7.37 1.94-5.18 1.94 5.18H4.97zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z"/>
                    </svg>
                    <div className="menu-text">Browse All</div>
                  </NavLink>
                  <NavLink to="/video/browse/recent">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 15H7.3l-2.55-3.5V15H3.5V9h1.25l2.5 3.5V9H8.5v6zm5-4.74H11v1.12h2.5v1.26H11v1.11h2.5V15h-4V9h4v1.26zm7 3.74c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9h1.25v4.51h1.13V9.99h1.25v3.51h1.12V9h1.25v5z"/>
                    </svg>
                    <div className="menu-text">Recently Added</div>
                  </NavLink>
                  <NavLink to="/video/browse/most-popular">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
                    </svg>
                    <div className="menu-text">Most Popular</div>
                  </NavLink>
                  <NavLink to="/video/browse/trending">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/>
                    </svg>
                    <div className="menu-text">Trending</div>
                  </NavLink>
                  <NavLink to="/video/browse/favourites">
                    <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
                    </svg>
                    <div className="menu-text">Favourites</div>
                  </NavLink>
                </div>
              </div>
              <div className="menu-item">
                <a href={isLoggedIn() ? "/forum/sso?Target=/categories" : "/forum/categories"}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
                  </svg>
                  <div className="menu-text">Forum</div>
                </a>
              </div>
              <div className="menu-item">
                <NavLink to="/upload" exact={true}>
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"/>
                  </svg>
                  <div className="menu-text">Upload</div>
                </NavLink>
              </div>
              <div className="menu-item">
                <NavLink to="/faq">
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"></path>
                  </svg>
                  <div className="menu-text">FAQ</div>
                </NavLink>
              </div>
              <div className="menu-item">
                <NavLink to="/contact-us">
                  <svg className="svg-icon menu-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z"/>
                  </svg>
                  <div className="menu-text">Contact Us</div>
                </NavLink>
              </div>
              <div className="menu-ad">
              {
                isAdsEnabled() && showAds('6586068039')
              }
              </div>
            </div>
            <div className="menu-overlay" onClick={this.props.onMenuClose}/>
          </div>
        </div>
    );
  }
}

export default withRouter(Menu);
