import './Header.css';
import logo from '../../Assets/Images/logo.png';
import {Link} from "react-router-dom";

function Header() {
  return (
      <Link to="/" className="header">
        <img src={logo} alt="Jafri Library"/>
      </Link>
  );
}

export default Header;
